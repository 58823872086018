import { createContext, FC, useEffect } from "react";
import * as socketClient from "socket.io-client";
import { useUser } from "../hooks/useUser";

const socket = socketClient.connect("https://chat_server.amplimed.com.br", {
  extraHeaders: {
    Authorization:
      localStorage.getItem("token") ||
      new URLSearchParams(window.location.search).get("token") ||
      "",
  },
});

const SocketContext = createContext(socket);

const SocketContextProvider: FC = ({ children }) => {
  const { user } = useUser();

  // Remove os listeners do socket quando o chat é desmontado
  useEffect(() => {
    return () => {
      socket.off();
    };
  }, []);

  // Realiza login
  useEffect(() => {
    socket.emit("login", user);

    socket.on("connect", () => {
      console.log("Usuario reconectado");
      socket.emit("login", user);
    });
  }, [user]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketContextProvider, SocketContext };

import { useEffect } from "react";
import { SocketContextProvider } from "./context/SocketContext";
import ChatApp from "./pages/ChatApp";
import { ChatProvider } from "./context/ChatContext";

const App = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    localStorage.setItem("token", queryParams.get("token") || "");
  }, []);

  const handleMessageEvent = (e: MessageEvent) => {
    switch (e.data.event) {
      case "open_chat":
        localStorage.setItem("chat_open", e.data.value.chatOpen || "true");
        localStorage.setItem(
          "chat_window",
          localStorage.getItem("chat_window") || "home"
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);

    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  });

  return (
    <ChatProvider>
      <SocketContextProvider>
        <ChatApp />
      </SocketContextProvider>
    </ChatProvider>
  );
};

export default App;
